import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import ReactQuill from 'react-quill'; // Import React-Quill
import 'react-quill/dist/quill.snow.css'; // Quill Snow theme
import { v4 as uuidv4 } from 'uuid';
import { createPost, deletePost, editPost, getAllPosts, getPostById } from '../services/blogsApi';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
function DashboardPage() {
  const [blogs, setBlogs] = useState([]);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [content, setContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState(null);

  // Fetch blogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const querySnapshot = await getAllPosts()
      setBlogs(querySnapshot.data);
    } catch (error) {
      console.error("Error fetching blogs: ", error);
    }
  };

  // Add a new blog
  const handleAddBlog = async () => {
    try {
      const newBlog = {
        name,
        image,
        content,
        createdAt: new Date().toISOString(),
      };

      await createPost(newBlog);

      setBlogs([...blogs, newBlog]);
      setName('');
      setImage('');
      setContent('');
    } catch (error) {
      console.error("Error adding blog: ", error);
    }
  };

  // Delete a blog
  const handleDeleteBlog = async (id) => {
    try {
      await deletePost(id);
      setBlogs(blogs.filter(blog => blog.id !== id));
    } catch (error) {
      console.error("Error deleting blog: ", error);
    }
  };

  // Handle edit blog click
  const handleEditBlog = (blog) => {
    setIsEditing(true);
    setCurrentBlogId(blog.id);
    setName(blog.name);
    setImage(blog.image);
    setContent(blog.content);
  };

  // Handle saving edited blog
  const handleSaveEdit = async () => {
    try {
      const updatedBlog = {
        id: currentBlogId,
        name,
        image,
        content,
        createdAt: new Date().toISOString(),
      };

      // Call update API or method to save changes
      await editPost(currentBlogId, updatedBlog); // Assuming updatePost function exists in the API

      setBlogs(blogs.map(blog => blog.id === currentBlogId ? updatedBlog : blog));
      setIsEditing(false);
      setCurrentBlogId(null);
      setName('');
      setImage('');
      setContent('');
    } catch (error) {
      console.error("Error updating blog: ", error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={4}>
      <h1>Admin Page</h1>
      <Box display="flex" flexDirection="column" alignItems="center" p={4} width="100%">
        <TextField
          label="Name"
          value={name}
          width="100%"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Image"
          value={image}
          width="100%"
          onChange={(e) => setImage(e.target.value)}
          margin="normal"
        />
        <Box mt={2} width="100%">
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, false] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['blockquote', 'code-block'],
                ['link', 'image'],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
              ],
            }}
            formats={[
              'header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet',
              'blockquote', 'code-block', 'link', 'image', 'color', 'background', 'align',
            ]}
          />
        </Box>

        {isEditing ?
          <Button variant="contained" color="secondary" onClick={handleSaveEdit} sx={{ mt: 2 }}>
            Save Edit
          </Button> : <Button variant="contained" color="primary" onClick={handleAddBlog} sx={{ mt: 2 }}>
            Add Blog
          </Button>
        }
      </Box>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Image</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs.map((blog) => (
              <TableRow key={blog.id}>
                <TableCell>{blog.name}</TableCell>
                <TableCell align="right">
                  <img src={blog.image} alt={blog.name} style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} />
                </TableCell>
                <TableCell align="right">
                  {isEditing && currentBlogId === blog.id ? (
                    <IconButton color="primary" onClick={() => {
                      setIsEditing(false);
                      setCurrentBlogId(null);
                      setName('');
                      setImage('');
                      setContent('');
                    }}>
                      <EditOffIcon />
                    </IconButton>

                  ) : (
                    <IconButton color="error" onClick={() => handleDeleteBlog(blog.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {!isEditing && currentBlogId !== blog.id && (
                    <IconButton color="primary" onClick={() => handleEditBlog(blog)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DashboardPage;
